@import "../../assets/styles/mixins";

.experience-card {
    @include timeline-content-card;

    .responsibilities {
        list-style-type: none;

        &__item {
            margin: 1rem 0;
            line-height: 1.7;
            background-color: rgba($color: #000000, $alpha: 0.3);
            padding: .4rem .6rem;
            border-radius: .4rem;
        }
    }

    &__stacks {
        margin-bottom: 0;
    }

    &__company {
        font-family: "Montaga", sans-serif;
        color: var(--color-text-grey);
        line-height: 1.7;
    }
}
