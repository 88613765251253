@import "../../assets/styles/mixins";

.Projects {
  background-color: var(--color-primary);
  .projectCards {
    margin-top: 5rem;

    @include mobile {
      text-align: center;
    }
    .card {
      display: flex;
      align-items: center;

      margin: 5rem auto;
      justify-content: space-between;
      padding-bottom: 2rem;
      flex-direction: row;

      @include mobile {
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }

      &:not(:last-child) {
        border-bottom: 1px solid rgba($color: #777, $alpha: 0.1);
      }

      &:nth-child(even) {
        flex-direction: row-reverse;
        @include mobile {
          flex-direction: column !important;
        }
      }

      &Img {
        background-color: yellow;
        margin-right: 2rem;

        @include mobile {
          margin-right: 0;
        }

        border-radius: 1rem;
        padding: 10rem;
      }

      &Content {
        flex: 1;
      }
    }
  }

  .projectDescription {
    line-height: 2;
  }
}
