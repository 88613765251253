@import "../../assets/styles/mixins";

.card {
    padding: 1rem 2rem;
    min-height: 30rem;
    border-radius: 0.4rem;
    background-color: rgba($color: #000000, $alpha: 0.3);
    text-align: center;

    &:not(:first-child) {
        padding-top: 2.5rem;
    }

    @include mobile {
        border-radius: 0;
    }

    .techStacks {
        width: 100%;

        .techStack {
            display: inline-block;
            padding: 0.5rem 1rem;
            margin: 0.2rem 0;
            background-color: var(--color-text-dark);
            border-radius: 4px;

            &:not(:last-child) {
                margin-right: 0.5rem;
            }
        }
    }

    &Title {
        color: var(--color-text-white);
        font-size: 2.4rem;
    }

    &Description {
        color: darken($color: #eee, $amount: 10);
        text-align: center;
        margin-top: 1rem;
        margin-bottom: 1rem;
        line-height: 1.5;
        text-align: justify;
    }
}
