:root {
  --color-primary: #39375b;
  --color-secondary: #745c97;
  --color-tertiary: #2f2e4d;
  --color-bg-2: #57548c48;
  --color-primary-accent: #fff;
  --color-grey-primary: #c4bbb8;
  --color-text-dark: #555;
  --color-text-accent: #fcb9b2;
  --color-text-grey: #c8c8c8;
  --color-link: #ff5c00;
  --font-default: "Open Sans", sans-serif;
  --font-title: "Josefin Sans", sans-serif;
  --font-special-heading: "Montaga", sans-serif;
}

$color-primary: var(--color-grey-primary);
$color-secondary: var(--color-primary);
$color-tertiary: var(--color-tertiary);
$color-primary-accent: var(--color-primary-accent);
$color-grey-primary: var(--color-grey-primary);
$color-text-dark: var(--color-text-dark);
$color-text-accent: var(--color-text-accent);
$color-text-grey: var(--color-text-grey);
$color-link: #ff5c00;
$font-default: var(--font-default);
$font-title: var(--font-title);
$font-special-heading: var(--font-special-heading);
