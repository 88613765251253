@import "../../assets/styles/mixins";
.EducationalBackground {
  background-color: var(--color-tertiary);

  .content {
    width: 85%;
    margin: 0 auto;
    @include tablet-mobile {
      width: unset;
    }

    .education {
      &Card {
        margin-top: 2rem;
        margin: 0 auto;

        &:not(:first-child) {
          margin: 10rem 0;
        }

        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(40rem, 1fr));
      }

      &Degree {
        margin-top: 2rem;

        @include tablet-mobile {
          text-align: left;
          margin-left: 2rem;
        }

        @include mobile {
          text-align: center;
          margin-left: unset;
        }
      }

      &Duration {
        width: fit-content;
        color: var(--color-text-accent);
        font-size: 3.2rem;
        min-width: 40rem !important;

        @include tablet-mobile {
          font-size: 2.8rem;
        }

        @include mobile {
          font-size: 2rem;
          width: 100%;
          min-width: unset;
          text-align: center;
        }
      }

      &Content {
        @include mobile {
          margin-left: unset;
          text-align: center;
        }
      }

      &Description {
        max-width: 70rem;
        letter-spacing: 1px;

        @include tablet-mobile {
          max-width: unset;
          line-height: 2;
        }
      }

      &Title {
        color: var(--color-text-grey);
        font-family: "Montaga", sans-serif;
        font-size: 2.4rem;
        font-weight: normal;
      }

      &Institution {
        @include tablet-mobile {
          font-size: 2.8rem;
        }

        @include mobile {
          font-size: 2.8rem;
        }
      }
    }
  }
}
