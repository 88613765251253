@import "../../../assets/styles/colors";
.btn {
  padding: 1.2rem 2rem;
  text-transform: uppercase;
  box-shadow: none;
  outline: none;
  border: none;
  border-radius: 2px;
  font-size: 1.8rem;
  transition: all ease-in-out 0.4s;
  box-shadow: 0 1px 2px rgba($color: #000000, $alpha: 0.2);

  a,
  a:visited,
  a:link {
    color: var(--color-white);
    &:hover {
      text-decoration: none;
    }
  }

  &--big {
    padding: 1.5rem 3rem;
  }

  &--primary {
    background-color: $color-secondary;
    color: $color-primary-accent;

    &:hover,
    &:active {
      background-color: darken($color-secondary, 5);
      cursor: pointer;
      transform: scale(1.1);
    }
  }
}
