@import "../../assets/styles/mixins";
.CTASection {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 50vh;
  flex-direction: column;
  text-align: center;

  &Description {
    max-width: 50rem;
    text-align: center;
    line-height: 2;

    @include mobile-l {
      max-width: 95vw;
      margin-left: auto;
      margin-right: auto;
    }
  }
}
